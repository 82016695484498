import React, { useState, useEffect } from 'react';
import { Select, Radio } from 'antd';

const { Option, OptGroup } = Select;

const FilterPanel = ({ factories, onFactorySelect, onIndicatorChange }) => {
    const [selectedFactoryIndex, setSelectedFactoryIndex] = useState(0);  // Индекс текущего завода

    // Упрощаем доступ к списку всех заводов
    const flattenedFactories = factories.flatMap(cluster => cluster.factories);

    // Выбор завода на основе индекса
    useEffect(() => {
        if (flattenedFactories.length > 0) {
            onFactorySelect(flattenedFactories[selectedFactoryIndex]);
        }
    }, [selectedFactoryIndex, flattenedFactories, onFactorySelect]);

    // Обработчики для кнопок переключения
    const handlePreviousFactory = () => {
        setSelectedFactoryIndex(prevIndex =>
            prevIndex > 0 ? prevIndex - 1 : flattenedFactories.length - 1  // Зацикливаем на последнем заводе
        );
    };

    const handleNextFactory = () => {
        setSelectedFactoryIndex(prevIndex =>
            prevIndex < flattenedFactories.length - 1 ? prevIndex + 1 : 0  // Зацикливаем на первом заводе
        );
    };

    // Обработчик нажатия клавиш для перемещения по списку заводов
        const handleKeyDown = (e) => {
            // Проверяем, что элемент, который в фокусе, не является радио-кнопкой
            const activeElement = document.activeElement;
            if (activeElement.tagName.toLowerCase() === 'input' && activeElement.type === 'radio') {
                return;  // Игнорируем нажатие стрелок для радио-кнопок
            }

            if (e.key === 'ArrowLeft') {
                handlePreviousFactory();  // Перемещение на предыдущий завод
            } else if (e.key === 'ArrowRight') {
                handleNextFactory();  // Перемещение на следующий завод
            }
        };

    // Добавляем обработчик события нажатия клавиш
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedFactoryIndex]);  // Обновляем обработчик при изменении индекса

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
            backgroundColor: '#f5f5f5',
            borderBottom: '1px solid #ddd',
            width: '100%',
            boxSizing: 'border-box',
        }}>
            <div>
                {/* Кнопка влево для переключения завода */}
                <button
                    className="monthly-reports-page__arrow-button"
                    onClick={handlePreviousFactory}
                    style={{ cursor: 'pointer', padding: '10px' }}>
                    ◀
                </button>

                {/* Выпадающий список для выбора завода */}
                <Select
                    showSearch
                    placeholder="Выберите завод"
                    optionFilterProp="children"
                    value={flattenedFactories[selectedFactoryIndex]?.id}  // Выбранный завод
                    onChange={(value) => {
                        const index = flattenedFactories.findIndex(factory => factory.id === value);
                        if (index !== -1) {
                            setSelectedFactoryIndex(index);
                        }
                    }}
                    style={{ width: '300px'}}
                    filterOption={(input, option) =>
                        option.children && typeof option.children === 'string' &&
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0  // Добавлена проверка на существование и тип данных
                    }
                >
                    {factories.map(cluster => (
                        <OptGroup key={cluster.id} label={cluster.name}>
                            {cluster.factories.map(factory => (
                                <Option key={factory.id} value={factory.id}>
                                    {factory.name}
                                </Option>
                            ))}
                        </OptGroup>
                    ))}
                </Select>

                {/* Кнопка вправо для переключения завода */}
                <button
                    className="monthly-reports-page__arrow-button"
                    onClick={handleNextFactory}
                    style={{ cursor: 'pointer', padding: '10px' }}
                    >
                    ▶
                </button>
            </div>

            {/* Радио-кнопки для выбора показателя */}
            <Radio.Group onChange={onIndicatorChange} defaultValue="all">
                <Radio value="city">В городе</Radio>
                <Radio value="city_mvo">В городе + МВО</Radio>
                <Radio value="all">Все</Radio>
            </Radio.Group>
        </div>
    );
};

export default FilterPanel;
