import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
    getAllActiveFactories,
    getTimesheetForFactoryAndMonth,
    uploadTimesheetPart,
    clearTimesheet,
    getAggregatedDailyReports,
    downloadDiscrepancyMatrix,
} from '../../api';
import ExcelParser from './ExcelParser';
import './MonthlyReportsPage.css';
import { format, addMonths, subMonths } from 'date-fns';
import Toast from '../Toast';
import * as XLSX from 'xlsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const MonthlyReportsPage = () => {
    const [viewMode, setViewMode] = useState('differenceMatrix'); // 'differenceMatrix' or 'factoryTimesheet'
    const [factories, setFactories] = useState([]);
    const [factoryData, setFactoryData] = useState([]);
    const [selectedFactory, setSelectedFactory] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(format(new Date(), 'yyyy-MM')); // Current month by default
    const [displayData, setDisplayData] = useState(null); // For timesheet data
    const [loading, setLoading] = useState(false); // Loading state
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(''); // success or error

    // ** New State for Number of Days in Selected Month **
    const [daysInSelectedMonth, setDaysInSelectedMonth] = useState(31);

    // Function to calculate the number of days in a given month
    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    // Update daysInSelectedMonth whenever selectedMonth changes
    useEffect(() => {
        const [year, month] = selectedMonth.split('-').map(Number);
        const days = getDaysInMonth(year, month - 1); // month is 0-based
        setDaysInSelectedMonth(days);
    }, [selectedMonth]);

    // Load list of factories
    useEffect(() => {
        setLoading(true);
        getAllActiveFactories()
            .then(response => {
                setFactories(response.data.map(factory => ({
                    value: factory.id,
                    label: factory.name,
                    id: factory.id,
                    name: factory.name,
                })));
                setLoading(false);
            })
            .catch(error => {
                console.error('Ошибка при загрузке заводов:', error);
                setLoading(false);
                setToastMessage(error.response?.data?.message || 'Ошибка при загрузке заводов. Пожалуйста, попробуйте позже.');
                setToastType('error');
            });
    }, []);

    // Load data for all factories when in differenceMatrix mode
    useEffect(() => {
        if (viewMode === 'differenceMatrix' && factories.length > 0) {
            loadDataForAllFactories();
        }
    }, [selectedMonth, viewMode, factories]);

    // Load timesheet when factory and month are selected in factoryTimesheet mode
    useEffect(() => {
        if (viewMode === 'factoryTimesheet' && selectedFactory && selectedMonth) {
            handleLoadTimesheet();
        }
    }, [selectedFactory, selectedMonth, viewMode]);

    const loadDataForAllFactories = async () => {
        setLoading(true);
        try {
            const promises = factories.map(async (factory) => {
                const factoryId = factory.id;
                // Fetch timesheet
                const timesheetResponse = await getTimesheetForFactoryAndMonth(factoryId, selectedMonth);
                const timesheetData = timesheetResponse.data && timesheetResponse.data.data ? timesheetResponse.data.data : null;

                // Fetch aggregated daily reports
                const aggregatedData = await getAggregatedDailyReports(factoryId, selectedMonth);

                // Calculate discrepancies if timesheet is available
                let discrepancies = [];
                if (timesheetData) {
                    // Compute discrepancies
                    const workDayCounts = calculateWorkDayCounts(timesheetData);
                    discrepancies = calculateDiscrepancies(workDayCounts, aggregatedData);
                }

                return {
                    factory,
                    timesheetAvailable: !!timesheetData,
                    discrepancies,
                };
            });

            const data = await Promise.all(promises);
            setFactoryData(data);
        } catch (error) {
            console.error('Ошибка при загрузке данных по заводам:', error);
            setToastMessage('Ошибка при загрузке данных. Пожалуйста, попробуйте позже.');
            setToastType('error');
        }
        setLoading(false);
    };

    const calculateWorkDayCounts = (timesheetData) => {
        const workDayCounts = new Array(daysInSelectedMonth).fill(0);
        timesheetData.forEach((row) => {
            row.hours.slice(0, daysInSelectedMonth).forEach((hours, index) => {
                if (hours > 0) {
                    workDayCounts[index]++;
                }
            });
        });
        return workDayCounts;
    };

    const calculateDiscrepancies = (workDayCounts, aggregatedData) => {
        return workDayCounts.map((count, index) => {
            const dailyReportValue = aggregatedData && aggregatedData[index] ? aggregatedData[index] : 0;
            return count - dailyReportValue;
        });
    };

    const handleUploadTimesheet = (factory) => {
        setSelectedFactory({
            value: factory.id,
            label: factory.name,
        });
        setViewMode('factoryTimesheet');
    };

    // Load timesheet from database
    const handleLoadTimesheet = () => {
        if (!selectedFactory || !selectedMonth) {
            setToastMessage('Укажите завод и месяц');
            setToastType('warn');
            return;
        }

        setLoading(true);
        getTimesheetForFactoryAndMonth(selectedFactory.value, selectedMonth)
            .then(response => {
                if (response.data && Array.isArray(response.data.data)) {
                    setDisplayData(response.data.data);
                } else {
                    setDisplayData(null);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Ошибка при загрузке табеля:", error);
                setLoading(false);
                setDisplayData(null);
                setToastMessage(error.response?.data?.message || 'Ошибка при загрузке табеля. Пожалуйста, попробуйте позже.');
                setToastType('error');
            });
    };

    // Handle uploading part of the timesheet
    const handleUploadPart = (part) => {
        if (!selectedFactory || !selectedMonth) {
            alert("Выберите завод и месяц перед загрузкой.");
            return;
        }

        // Open file picker
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx,.xls';
        input.onchange = async (e) => {
            const file = e.target.files[0];
            if (file) {
                try {
                    const data = await parseExcelFile(file, part);
                    // Save the timesheet immediately after parsing
                    await saveTimesheetPart(data, part);
                } catch (error) {
                    console.error("Ошибка при парсинге файла Excel:", error);
                    alert(error.message || "Не удалось распознать файл Excel.");
                }
            }
        };
        input.click();
    };

    // Function to parse the Excel file and extract data for the specified part
    const parseExcelFile = (file, part) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    // Process the data to extract the relevant days
                    const parsedData = processTimesheetData(jsonData, part);
                    resolve(parsedData);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file);
        });
    };

    // Function to process the timesheet data and extract days 1-15 or 16-31
    const processTimesheetData = (jsonData, part) => {
        // Validate input
        if (!Array.isArray(jsonData) || jsonData.length === 0) {
            throw new Error("Excel-файл пуст или не в ожидаемом формате.");
        }

        // The first row is expected to be the header row
        const headers = jsonData[0];

        // Find the index of the 'Name' column
        const nameIndex = headers.findIndex(header => {
            if (!header) return false;
            const headerStr = header.toString().toLowerCase().trim();
            return headerStr === 'имя' || headerStr === 'сотрудник' || headerStr.includes('имя') || headerStr.includes('сотрудник') || headerStr === 'фио';
        });

        if (nameIndex === -1) {
            throw new Error("Не удалось найти столбец 'Сотрудник' в Excel-файле.");
        }

        // Determine the indices for the day columns
        // Assuming that day columns are labeled with numbers (1, 2, 3, ...)
        const dayIndices = [];
        for (let i = 0; i < headers.length; i++) {
            const header = headers[i];
            if (header === undefined || header === null || header === '') continue;
            const headerStr = header.toString().trim();
            const day = parseInt(headerStr);
            if (!isNaN(day) && day >= 1 && day <= 31) {
                dayIndices.push({ index: i, day: day });
            }
        }

        // Filter the dayIndices based on the 'part'
        let startDay, endDay;
        if (part === 'firstHalf') {
            startDay = 1;
            endDay = 15;
        } else if (part === 'secondHalf') {
            startDay = 16;
            endDay = daysInSelectedMonth;
        } else {
            throw new Error("Invalid part specified. Must be 'firstHalf' or 'secondHalf'.");
        }

        const selectedDayIndices = dayIndices.filter(di => di.day >= startDay && di.day <= endDay);

        if (selectedDayIndices.length === 0) {
            throw new Error(`Не найдены столбцы дней для указанной части месяца (${part  === 'firstHalf' ? '0-15' : '16-30/31'}).`);
        }

        // Now process each data row
        const parsedData = [];

        for (let i = 1; i < jsonData.length; i++) { // Start from 1 to skip headers
            const row = jsonData[i];

            const name = row[nameIndex];
            if (!name) continue; // Skip rows without a name

            const hours = [];

            for (let di of selectedDayIndices) {
                let value = row[di.index];
                value = parseHours(value);
                hours.push(value);
            }

            parsedData.push({
                name,
                hours,
            });
        }

        return parsedData;
    };

    // Function to parse cell values
    const parseHours = (cell) => {
        if (!cell || typeof cell !== 'string') return 0;

        const workPatterns = ["ВМЛ", "ВМ", "Я"];
        for (let pattern of workPatterns) {
            if (cell.startsWith(pattern)) {
                const hours = parseFloat(cell.replace(pattern, '').trim());
                return !isNaN(hours) ? hours : 0;
            }
        }
        return 0; // Если формат не совпал
    };

    // Function to save the timesheet part to the backend
    const saveTimesheetPart = async (data, part) => {
        setLoading(true);
        try {
            await uploadTimesheetPart({
                factoryId: selectedFactory.value,
                month: selectedMonth,
                data,
                part,
            });
            setToastMessage(`Табель за дни ${part === 'firstHalf' ? '1-15' : '16-31'} успешно загружен.`);
            setToastType('success');
            // Reload the timesheet to reflect changes
            handleLoadTimesheet();
        } catch (error) {
            console.error("Ошибка при сохранении табеля:", error);
            setToastMessage(error.response?.data?.message || 'Не удалось сохранить табель.');
            setToastType('error');
        }
        setLoading(false);
    };

    // Function to clear the timesheet
    const handleClearTimesheet = async () => {
        if (!selectedFactory || !selectedMonth) {
            alert("Выберите завод и месяц перед очисткой.");
            return;
        }

        if (window.confirm("Вы уверены, что хотите очистить табель?")) {
            setLoading(true);
            try {
                await clearTimesheet(selectedFactory.value, selectedMonth);
                setToastMessage("Табель успешно очищен.");
                setToastType('success');
                // Reload the timesheet to reflect changes
                setDisplayData(null);
                setLoading(false);
            } catch (error) {
                console.error("Ошибка при очистке табеля:", error);
                setToastMessage(error.response?.data?.message || 'Не удалось очистить табель.');
                setToastType('error');
                setLoading(false);
            }
        }
    };

    const isFilterDisabled = false; // Filters are not disabled anymore

    const handleMonthChange = (e) => {
        if (!isFilterDisabled) {
            setSelectedMonth(e.target.value);
        }
    };

    const decrementMonth = () => {
        if (!isFilterDisabled) {
            setSelectedMonth(format(subMonths(new Date(selectedMonth), 1), 'yyyy-MM'));
        }
    };

    const incrementMonth = () => {
        if (!isFilterDisabled) {
            setSelectedMonth(format(addMonths(new Date(selectedMonth), 1), 'yyyy-MM'));
        }
    };

    const handleFactoryChange = (factory) => {
        setSelectedFactory(factory);
    };

    const getFactoryIndex = () => {
        return factories.findIndex(f => f.value === selectedFactory?.value);
    };

    const decrementFactory = () => {
        const currentIndex = getFactoryIndex();
        if (currentIndex > 0) {
            setSelectedFactory(factories[currentIndex - 1]);
        }
    };

    const incrementFactory = () => {
        const currentIndex = getFactoryIndex();
        if (currentIndex < factories.length - 1) {
            setSelectedFactory(factories[currentIndex + 1]);
        }
    };

    // Handle upload not allowed
    const handleUploadNotAllowed = () => {
        if (!selectedFactory && !selectedMonth) {
            setToastMessage('Пожалуйста, выберите завод и месяц перед загрузкой табеля.');
            setToastType('error');
        } else if (!selectedFactory) {
            setToastMessage('Пожалуйста, выберите завод перед загрузкой табеля.');
            setToastType('error');
        } else if (!selectedMonth) {
            setToastMessage('Пожалуйста, выберите месяц перед загрузкой табеля.');
            setToastType('error');
        }
    };

    const handleExportToExcel = () => {
        downloadDiscrepancyMatrix(selectedMonth, factoryData)
            .then(() => {
                console.log('Отчет успешно выгружен');
            })
            .catch((error) => {
                console.error('Ошибка при скачивании файла', error);
                setToastMessage('Ошибка при скачивании файла. Пожалуйста, попробуйте позже.');
                setToastType('error');
            });
    };

    // Render functions
    const renderDifferenceMatrixTable = () => {
        if (factoryData.length === 0) {
            return <div>Данные не найдены или некорректны.</div>;
        }

        return (
            <div className="excel-table-container">
                <table className="excel-table">
                    <thead>
                        <tr>
                            <th>Завод</th>
                            {[...Array(daysInSelectedMonth).keys()].map(day => (
                                <th key={day + 1}>{day + 1}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {factoryData.map((factoryItem) => (
                            <tr key={factoryItem.factory.id}>
                                <td className="factory-name-cell">
                                    {factoryItem.factory.name}
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        className="view-timesheet-icon"
                                        onClick={() => handleUploadTimesheet(factoryItem.factory)}
                                        title="Просмотр табеля"
                                    />
                                </td>
                                {factoryItem.timesheetAvailable ? (
                                    factoryItem.discrepancies.slice(0, daysInSelectedMonth).map((diff, index) => (
                                        <td
                                            key={index}
                                            className={
                                                diff > 0
                                                    ? 'highlight-difference-plus'
                                                    : diff < 0
                                                        ? 'highlight-difference-minus'
                                                        : 'highlight-difference'
                                            }
                                        >
                                            {diff}
                                        </td>
                                    ))
                                ) : (
                                    <td colSpan={daysInSelectedMonth} className="no-timesheet-cell">
                                        <button
                                            className="upload-timesheet-button"
                                            onClick={() => handleUploadTimesheet(factoryItem.factory)}
                                        >
                                            Загрузить табель
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderTimesheetTable = (data) => {
        if (!data || data.length === 0) {
            return <div><i>Табель пуст</i></div>;
        }

        return (
            <div className="excel-table-container">
                <table className="excel-table">
                    <thead>
                        <tr>
                            <th>Сотрудник</th>
                            {[...Array(daysInSelectedMonth).keys()].map(day => (
                                <th key={day + 1}>{day + 1}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{row.name}</td>
                                {row.hours.slice(0, daysInSelectedMonth).map((hours, dayIndex) => (
                                    <td key={dayIndex}>{hours}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="monthly-reports-page">
            {viewMode === 'differenceMatrix' ? (
                <>
                    <h1 className="monthly-reports-page__title">Табель - Матрица расхождений</h1>
                    <div className="monthly-reports-page__controls">
                        <div className="monthly-reports-page__control-group">
                            <button
                                className="monthly-reports-page__arrow-button"
                                onClick={decrementMonth}
                            >
                                &#9664;
                            </button>
                            <input
                                type="month"
                                value={selectedMonth}
                                onChange={handleMonthChange}
                                placeholder="Выберите месяц"
                                className="monthly-reports-page__month-input"
                            />
                            <button
                                className="monthly-reports-page__arrow-button"
                                onClick={incrementMonth}
                            >
                                &#9654;
                            </button>
                        </div>
                        {<button
                            onClick={handleExportToExcel}
                            className="monthly-reports-page__button monthly-reports-page__button--export"
                        >
                            Сохранить в Excel
                        </button>}
                    </div>
                    <div className="monthly-reports-page__table-container">
                        {renderDifferenceMatrixTable()}
                    </div>
                </>
            ) : (
                <>
                    <h1 className="monthly-reports-page__title">Табель - {selectedFactory?.label}</h1>
                    <div className="monthly-reports-page__controls">
                        <button
                            onClick={() => setViewMode('differenceMatrix')}
                            className="monthly-reports-page__button monthly-reports-page__button--back"
                        >
                            К матрице
                        </button>
                        <div className="monthly-reports-page__control-group">
                            <button
                                className="monthly-reports-page__arrow-button"
                                onClick={decrementFactory}
                                disabled={isFilterDisabled}
                            >
                                &#9664;
                            </button>
                            <Select
                                id="factory-select"
                                options={factories}
                                value={selectedFactory}
                                onChange={handleFactoryChange}
                                placeholder="Выберите завод"
                                className="monthly-reports-page__select"
                                isDisabled={isFilterDisabled}
                            />
                            <button
                                className="monthly-reports-page__arrow-button"
                                onClick={incrementFactory}
                                disabled={isFilterDisabled}
                            >
                                &#9654;
                            </button>
                        </div>
                        <div className="monthly-reports-page__control-group">
                            <button
                                className="monthly-reports-page__arrow-button"
                                onClick={decrementMonth}
                                disabled={isFilterDisabled}
                            >
                                &#9664;
                            </button>
                            <input
                                type="month"
                                value={selectedMonth}
                                onChange={handleMonthChange}
                                placeholder="Выберите месяц"
                                className="monthly-reports-page__month-input"
                                disabled={isFilterDisabled}
                            />
                            <button
                                className="monthly-reports-page__arrow-button"
                                onClick={incrementMonth}
                                disabled={isFilterDisabled}
                            >
                                &#9654;
                            </button>
                        </div>
                    </div>

                    <div className="monthly-reports-page__upload-buttons">
                        <button
                            onClick={() => handleUploadPart('firstHalf')}
                            className="monthly-reports-page__button"
                        >
                            Загрузить 1-15
                        </button>
                        <button
                            onClick={() => handleUploadPart('secondHalf')}
                            className="monthly-reports-page__button"
                        >
                            Загрузить 16-31
                        </button>
                        <button
                            onClick={handleClearTimesheet}
                            className="monthly-reports-page__button monthly-reports-page__button--clear"
                        >
                            Очистить
                        </button>
                    </div>

                    <div className="monthly-reports-page__table-container">
                        {renderTimesheetTable(displayData)}
                    </div>
                </>
            )}

            {loading && (
                <div className="monthly-reports-page__loading-overlay">
                    <div className="monthly-reports-page__loader"></div>
                    <p>Загрузка данных...</p>
                </div>
            )}

            {toastMessage && (
                <Toast
                    message={toastMessage}
                    type={toastType}
                    onClose={() => setToastMessage(null)}
                />
            )}
        </div>
    );
};

export default MonthlyReportsPage;
