import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const ChartsPanel = ({ chartData, selectedFactory, indicator }) => {
    // Преобразуем данные для графика, форматируя дату
    const formattedChartData = chartData.map(item => ({
        ...item,
        date: new Date(item.date).toLocaleDateString('ru-RU'), // Преобразуем дату
    }));

    return (
        <div style={{ width: '95%', height: '70vh', paddingLeft: '20px' }}>
            <h2>График для {selectedFactory.name}</h2>

            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={formattedChartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, dataMax => Math.max(10, dataMax)]}  />
                    <Tooltip />
                    <Legend />

                    {indicator=='all' && (<Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="onVacationToday"
                        stroke="#FF8C00"
                        name="на МВО"
                    />)}

                    {(indicator=='city' || indicator=='all') && (<Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="totalInCity"
                        stroke="#8884d8"
                        strokeWidth={2}  // Толщина линии 3 пикселя
                        name="в городе"
                    />)}
                    {/* Линия для "combined" */}

                    {(indicator=='city_mvo' || indicator=='all') && (<Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="combined"
                        stroke="#000"
                        strokeWidth={3}  // Толщина линии 3 пикселя
                        name="В городе + МВО"
                    />)}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ChartsPanel;
