import React, { useState, useEffect, useRef } from 'react';
import { getMasterByLink, getFactoriesForMaster, createReport, getReportToday, getLastSavedReport } from '../../api';
import { useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './MasterReportForm.css';

const MasterReportForm = () => {
    const { uniqueLink } = useParams();
    const [master, setMaster] = useState(null);
    const [factories, setFactories] = useState([]);
    const [selectedFactory, setSelectedFactory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditable, setIsEditable] = useState(false);
    const [hasId, setHasId] = useState(false);
    const [report, setReport] = useState({
        atStationToday: 0,
        totalInCity: 0,
        newWorkersToday: 0,
        onMedicalToday: 0,
        leftFactoryToday: 0,
        onVacationToday: 0,
        requestToday: '',
        requestTodayComment: '',
        whoOnApproval: '',
        whoInCity: '',
        whoAtFactory: '',
        whoOnSickLeave: '',
        whoReturnedFromVacation: '',
        whoLeftForVacation: '',
        whoFiredToday: '',
        whoOtrabotkaToday: ''
    });
    const [initialReport, setInitialReport] = useState(null); // Для хранения изначальных данных отчета
    const [showConfirmation, setShowConfirmation] = useState(false); // Новое состояние для отображения подтверждения


    const currentReportDate = new Date().toISOString().split('T')[0];
    const currentReportDateTitle = new Date().toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
    const currentReportDateTitleWeekday = new Date().toLocaleDateString('ru-RU', { weekday: 'long' });


    const textAreasRefs = useRef([]);

    useEffect(() => {
        setLoading(true);
        getMasterByLink(uniqueLink)
            .then(master => {
                setMaster(master);
                return getFactoriesForMaster(master.id);
            })
            .then(factoriesResponse => {
                setFactories(factoriesResponse.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching master or factories data', error);
                setLoading(false);
            });
    }, [uniqueLink]);

    useEffect(() => {
        if (factories.length === 1) {
            setSelectedFactory(factories[0]);
        }
    }, [factories]);

    useEffect(() => {
        if (selectedFactory && master) {
            getReportToday(selectedFactory.id)
                .then(response => {
                    if (response?.data?.id) {
                        setReport(response.data);
                        setInitialReport(response.data);
                        // Если отчет заполнен не нулями, ставим режим просмотра
                        if (response.data.id) {
                            setHasId(true);
                            setIsEditable(false); // Режим просмотра
                        } else {
                            setHasId(false);
                            setIsEditable(true); // Режим редактирования
                        }
                    } else {
                        // Если отчета за сегодня нет, подгружаем отчет за последний день
                                            getLastSavedReport(selectedFactory.id)
                                                .then(lastReport => {
                                                    if (lastReport.data) {
                                                        setReport({
                                                            ...lastReport.data,
                                                            id: null,  // Обнуляем id, чтобы не перезаписать предыдущий отчет
                                                            reportDate: currentReportDate // Обновляем дату на текущую
                                                        });
                                                    } else {
                                                        setReport({});
                                                    }
                                                    setIsEditable(true); // Режим редактирования
                                                })
                                                .catch(error => {
                                                    console.error('Error fetching last saved report', error);
                                                });
                    }
                })
                .catch(error => {
                    console.error('Error fetching report data', error);
                });
        }
    }, [selectedFactory, master]);

    const handleFactorySelect = (factory) => {
        setSelectedFactory(factory);
    };

    const handleBackToList = () => {
        setSelectedFactory(null);
    };

    const handleSubmit = (e) => {
        console.log('handleSubmit');
            e.preventDefault();
            if (hasId) {
                setShowConfirmation(true); // Если есть Id отчета, показать запрос подтверждения
            } else {
                sendReport();
            }
    };

    const sendReport = () => {
            createReport({ ...report, master: master, factory: selectedFactory, reportDate: currentReportDate })
                .then(() => {
                    alert('Отчет успешно отправлен!');
                    setIsEditable(false); // Переключаем в режим просмотра
                    setShowConfirmation(false); // Скрываем окно подтверждения
                })
                .catch(error => {
                    console.error('Error submitting report', error);
                });
    };

    const handleConfirmSubmit = () => {
            sendReport(); // Отправляем отчет, если подтверждено
        };

    const handleCancelSubmit = () => {
            setShowConfirmation(false); // Отменяем подтверждение
        };



    const handleCancel = () => {
        setReport(initialReport); // Возвращаем исходное состояние отчета
        setIsEditable(false); // Выключаем режим редактирования
    };

    const handleEdit = () => {
        setIsEditable(true); // Включаем режим редактирования
    };

    const handleTextareaChange = (e, field) => {
        const text = e.target.value;
        setReport({ ...report, [field]: text });
        autoResizeTextarea(e.target);
    };

    const autoResizeTextarea = (textarea) => {
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.max(textarea.scrollHeight, 3 * 24)}px`;
    };

    const handleFocus = (e) => {
        e.target.select(); // Выделение текста при фокусе
    };

    if (loading) {
        return <div>Загрузка данных...</div>;
    }

    if (!master) {
        return <div>Мастер не найден.</div>;
    }

    if (factories.length === 0) {
        return <div>Отсутствуют закрепленные заводы.</div>;
    }

    if (!selectedFactory && factories.length > 1) {
        return (
            <div className="factory-select-container">
                <ul className="factory-list">
                    {factories.map(factory => (
                        <li key={factory.id}>
                            <button onClick={() => handleFactorySelect(factory)} className="factory-select-button">
                                {factory.name}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    return (
        <div className="report-form-container">
            <header className="report-header">
                {factories.length > 1 && (
                    <button onClick={handleBackToList} className="back-icon">
                        <FaArrowLeft size={20} />
                    </button>
                )}
                <h2>{selectedFactory?.name} | {currentReportDateTitle} ({currentReportDateTitleWeekday})</h2>
                {!isEditable && (
                    <button onClick={handleEdit} className="edit-button">Редактировать</button>
                )}
            </header>

            <form onSubmit={handleSubmit} className="report-form">
                <div className="form-group">
                    <label>Количество работников ВЧЕРА на смене ( У станка+ выходные по ГРАФИКУ!)</label>
                    <input
                        type="number"
                        min="0"
                        value={report.atStationToday||0}
                        onFocus={handleFocus}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, atStationToday: e.target.value })}
                        disabled={!isEditable} // Заблокировано, если не в режиме редактирования
                    />
                </div>
                <div className="form-group">
                    <label>Количество работников в городе ЗА ВЧЕРА? (те кто на смене + бл,адм, прогул, КРОМЕ МВО)</label>
                    <input
                        type="number"
                        min="0"
                        onFocus={handleFocus}
                        value={report.totalInCity||0}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, totalInCity: e.target.value })}
                    />
                </div>
                <div className="form-group">
                    <label>Сколько СЕГОДНЯ новых работников вышло на смену</label>
                    <input
                        type="number"
                        min="0"
                        onFocus={handleFocus}
                        value={report.newWorkersToday||0}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, newWorkersToday: e.target.value })}
                    />
                </div>
                <div  className="form-group">
                    <label>Сколько СЕГОДНЯ кандидатов на оформлении/заселилось/медосмотре/тест/экзамен/ТБ/собеседование?</label>
                    <input
                        type="number"
                        min="0"
                        onFocus={handleFocus}
                        value={report.onMedicalToday||0}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, onMedicalToday: e.target.value })}
                    />
                </div>
                <div className="form-group">
                    <label>Кол-во работников уволенных ЗА ВЧЕРА</label>
                    <input
                        type="number"
                        onFocus={handleFocus}
                        value={report.leftFactoryToday||0}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, leftFactoryToday: -Math.abs(e.target.value) })}
                    />
                </div>
                <div className="form-group">
                    <label>Сколько работников в межвахтовом отпуске (общее кол-во чел на МВО) ЗА ВЧЕРА</label>
                    <input
                        type="number"
                        min="0"
                        onFocus={handleFocus}
                        value={report.onVacationToday||0}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, onVacationToday: e.target.value })}
                    />
                </div>

                <div>
                    <label>Количество человек по заявке на СЕГОДНЯ (общая цифра)</label>
                    <input
                        type="number"
                        min="0"
                        onFocus={handleFocus}
                        value={report.requestToday||0}
                        disabled={!isEditable}
                        onChange={e => setReport({ ...report, requestToday: e.target.value })}
                    />
                </div>

                <div>
                    <label>Специальности по заявке НА СЕГОДНЯ (ПРИМЕР: 5 токарь, 3 фрезеровщик)</label>
                    <textarea
                        rows="2"
                                                value={report.requestTodayComment || ''}
                                                ref={el => textAreasRefs.current[0] = el}
                                                disabled={!isEditable}
                                                onChange={e => handleTextareaChange(e, 'requestTodayComment')}
                    />
                </div>


                <div>
                    <label>Кто в работе: приезд (фамилия и дата), СБ, ждет отработки, ждём док-ты</label>
                    <textarea
                        rows="2"
                        value={report.whoOnApproval || ''}
                        ref={el => textAreasRefs.current[0] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoOnApproval')}
                    />
                </div>
                <div>
                    <label>Кто СЕГОДНЯ на оформлении (Фамилия И.О.)</label>
                    <textarea
                        rows="2"
                        value={report.whoInCity || ''}
                        ref={el => textAreasRefs.current[1] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoInCity')}
                    />
                </div>
                <div>
                    <label>Кто Новенькие вышли СЕГОДНЯ на смену (Фамилия И.О.)</label>
                    <textarea
                        rows="2"
                        value={report.whoAtFactory || ''}
                        ref={el => textAreasRefs.current[2] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoAtFactory')}
                    />
                </div>
                <div>
                    <label>Кто на больничном/отгуле/прогуле/административном? (ФИО-причина кратко) ЗА ВЧЕРА</label>
                    <textarea
                        rows="2"
                        value={report.whoOnSickLeave || ''}
                        ref={el => textAreasRefs.current[3] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoOnSickLeave')}

                    />
                </div>
                <div>
                    <label>Кто вернулся с межвахты ВЧЕРА? (Фамилия И.О.)</label>
                    <textarea
                        rows="2"
                        value={report.whoReturnedFromVacation || ''}
                        ref={el => textAreasRefs.current[4] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoReturnedFromVacation')}
                    />
                </div>
                <div>
                    <label>Кто убыл на межвахту Фамилия И.О. и дата возврата ЗА ВЧЕРА?</label>
                    <textarea
                        rows="2"
                        value={report.whoLeftForVacation || ''}
                        ref={el => textAreasRefs.current[5] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoLeftForVacation')}
                    />
                </div>

                <div>
                    <label>Кто уволен ЗА ВЧЕРА? Фамилия И.О. и причина</label>
                    <textarea
                        rows="2"
                        value={report.whoFiredToday || ''}
                        ref={el => textAreasRefs.current[6] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoFiredToday')}
                    />
                </div>

                <div>
                    <label>Кто на отработке? Фамилия И.О. и планируемая дата увольнения (Заявление и отработка 2 недели по ТК РФ)</label>
                    <textarea
                        rows="2"
                        value={report.whoOtrabotkaToday || ''}
                        ref={el => textAreasRefs.current[6] = el}
                        disabled={!isEditable}
                        onChange={e => handleTextareaChange(e, 'whoOtrabotkaToday')}
                    />
                </div>

                <div className="form-buttons">
                                    {isEditable && (
                                        <>
                                            <button type="submit" className="submit-button">Сохранить и отправить</button>
                                            { hasId && (<button type="button" className="cancel-button" onClick={handleCancel}>Отмена</button>)}
                                        </>
                                    )}
                </div>
            </form>
                {/* Модальное окно подтверждения */}
                {showConfirmation && (
                 <>
                        <div className="modal-backdrop"></div>
                        <div className="confirmation-modal">
                            <p>Внимание!<br/>Вы уверены, что хотите изменить отчёт за <b>{currentReportDateTitle}</b> ({currentReportDateTitleWeekday})?</p>
                            <button onClick={handleConfirmSubmit} className="confirm-button">Да</button>
                            <button onClick={handleCancelSubmit} className="cancel-button">Отмена</button>
                        </div>
                 </>
                )}
        </div>
    );
};

export default MasterReportForm;