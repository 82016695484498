// SickLeavePage.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { format, subMonths, addMonths } from 'date-fns';
import { getAllClusters, getMonthlyReports, downloadSickLeaveReport } from '../../api'; // Импортируем новый метод для выгрузки отчёта
import './SickLeavePage.css';

const SickLeavePage = () => {
    const [factories, setFactories] = useState([]);
    const [selectedFactory, setSelectedFactory] = useState(null);
    const [month, setMonth] = useState(format(new Date(), 'yyyy-MM')); // Текущий месяц
    const [reports, setReports] = useState([]);
    const [warningMessage, setWarningMessage] = useState(null); // Для показа предупреждений

    // Получаем список заводов и кластеров при загрузке страницы
    useEffect(() => {
        getAllClusters(false)
            .then(response => {
                const clusters = response.data;
                const formattedFactories = clusters.map(cluster => ({
                    label: cluster.name,
                    options: cluster.factories.map(factory => ({
                        value: factory.id,
                        label: factory.name
                    }))
                }));
                setFactories(formattedFactories);
            })
            .catch(error => console.error('Ошибка при загрузке кластеров:', error));
    }, []);

    // Получаем отчеты по выбранному заводу и месяцу
    useEffect(() => {
        if (selectedFactory) {
            getMonthlyReports(selectedFactory.value, month)
                .then(response => setReports(response.data))
                .catch(error => console.error('Ошибка при загрузке отчетов:', error));
        }
    }, [selectedFactory, month]);

    // Функции для изменения месяца
    const previousMonth = () => {
        setMonth(format(subMonths(new Date(month), 1), 'yyyy-MM'));
    };

    const nextMonth = () => {
        setMonth(format(addMonths(new Date(month), 1), 'yyyy-MM'));
    };

    // Функции для изменения завода
    const previousFactory = () => {
        const clusterOptions = factories.flatMap(cluster => cluster.options);
        if (!selectedFactory && clusterOptions.length > 0) {
            // Если завод не выбран, выбираем первый завод
            setSelectedFactory(clusterOptions[0]);
            setWarningMessage(null); // Убираем предупреждение
        } else if (selectedFactory) {
            const currentIndex = clusterOptions.findIndex(option => option.value === selectedFactory.value);
            if (currentIndex > 0) {
                setSelectedFactory(clusterOptions[currentIndex - 1]);
            }
        } else {
            setWarningMessage('Укажите завод');
        }
    };

    const nextFactory = () => {
        const clusterOptions = factories.flatMap(cluster => cluster.options);
        if (!selectedFactory && clusterOptions.length > 0) {
            // Если завод не выбран, выбираем первый завод
            setSelectedFactory(clusterOptions[0]);
            setWarningMessage(null); // Убираем предупреждение
        } else if (selectedFactory) {
            const currentIndex = clusterOptions.findIndex(option => option.value === selectedFactory.value);
            if (currentIndex < clusterOptions.length - 1) {
                setSelectedFactory(clusterOptions[currentIndex + 1]);
            }
        } else {
            setWarningMessage('Укажите завод');
        }
    };

    // Функция для форматирования текста с переносами
    const formatTextWithLineBreaks = (text) => {
        return text?.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    // Функция для выгрузки отчета в Excel
    const handleDownloadExcel = () => {
        if (selectedFactory) {
            console.log(selectedFactory);
            downloadSickLeaveReport(selectedFactory.label, selectedFactory.value, month)
                .then(() => {
                    console.log('Отчет успешно выгружен');
                })
                .catch((error) => {
                    console.error('Ошибка при выгрузке отчета:', error);
                });
        } else {
            setWarningMessage('Укажите завод перед выгрузкой');
        }
    };

    return (
        <div className="sick-leave-page">
            <h2>Больничные по заводу</h2>
            <div className="sick-leave-filters">
                <div className="filter-group">
                    {/* Кнопки для переключения заводов */}
                    <button onClick={previousFactory} className="arrow-button">◀</button>
                    <Select
                        options={factories}
                        value={selectedFactory}
                        onChange={setSelectedFactory}
                        placeholder="Выберите завод"
                        className="factory-select"
                    />
                    <button onClick={nextFactory} className="arrow-button">▶</button>
                </div>

                <div className="filter-group">
                    {/* Кнопки для переключения месяцев */}
                    <button onClick={previousMonth} className="arrow-button">◀</button>
                    <input
                        type="month"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                        className="month-select"
                    />
                    <button onClick={nextMonth} className="arrow-button">▶</button>
                </div>

                {/* Кнопка для выгрузки отчета в Excel */}
                <button onClick={handleDownloadExcel} className="export-button">Выгрузить в Excel</button>
            </div>

            {/* Показываем предупреждение, если завод не выбран */}
            {warningMessage && (
                <p className="warning-message">{warningMessage}</p>
            )}

            {reports.length > 0 ? (
                <table className="sick-leave-table">
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Сотрудники на больничном</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((report) => (
                            <tr key={report.reportDate}>
                                <td>{format(new Date(report.reportDate), 'dd.MM.yyyy')}</td>
                                <td>{formatTextWithLineBreaks(report.whoOnSickLeave) || '—'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                selectedFactory && <p>Нет данных за выбранный период.</p>
            )}
        </div>
    );
};

export default SickLeavePage;
