export const colorOptions = [
           { number: 1, hex: '#FFDDC1' },  // Light peach
           { number: 2, hex: '#D4E157' },  // Lime green
           { number: 3, hex: '#81D4FA' },  // Light blue
           { number: 4, hex: '#FFAB91' },  // Soft orange
           { number: 5, hex: '#AED581' },  // Soft green
           { number: 6, hex: '#FFF59D' },  // Light yellow
           { number: 7, hex: '#FFCC80' },  // Light orange
           { number: 8, hex: '#B39DDB' },  // Soft purple
           { number: 9, hex: '#80CBC4' },  // Teal
           { number: 10, hex: '#FF8A65' }, // Coral
           { number: 11, hex: '#90CAF9' }, // Sky blue
           { number: 12, hex: '#E6EE9C' }, // Light lime
           { number: 13, hex: '#F8BBD0' }, // Light pink
           { number: 14, hex: '#A5D6A7' }, // Soft green
           { number: 15, hex: '#CE93D8' }, // Lavender
           { number: 16, hex: '#FFE082' }, // Soft yellow
           { number: 17, hex: '#80DEEA' }, // Soft cyan
           { number: 18, hex: '#C5E1A5' }, // Pale green
           { number: 19, hex: '#FFAB40' }, // Amber
           { number: 20, hex: '#FFCDD2' }  // Soft red
       ];


export const colorOptionsFind = (num) => {
    return colorOptions.find(option => option.number === num)?.hex || '#fff';
};
