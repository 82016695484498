// ManagerMenu.js

import React from 'react';
import { Link } from 'react-router-dom';
import {
    FaRegCalendarAlt,
    FaRegChartBar,
    FaIndustry,
    FaUserTie,
    FaChartPie,
    FaMedkit
} from 'react-icons/fa';
import './ManagerMenu.css';
import { downloadPivotReport } from '../api';

const handleDownloadPivotReport = () => {
    downloadPivotReport();
};

const ManagerMenu = () => {
    return (
        <nav className="manager-menu">
            <ul className="menu-left">
                <li>
                    <Link to="/manager/daily-reports">
                        <FaRegCalendarAlt className="menu-icon" /> Дневные отчеты
                    </Link>
                </li>
                <li>
                    <Link to="/manager/monthly-reports">
                        <FaRegChartBar className="menu-icon" /> Табель
                    </Link>
                </li>
                <li>
                    <Link to="/manager/factories">
                        <FaIndustry className="menu-icon" /> Заводы
                    </Link>
                </li>
                <li>
                    <Link to="/manager/masters">
                        <FaUserTie className="menu-icon" /> Мастера
                    </Link>
                </li>
                <li>
                    <Link to="/manager/analytics">
                        <FaChartPie className="menu-icon" /> Аналитика
                    </Link>
                </li>

                <li>
                    <Link to="/manager/sick-leave">
                        <FaMedkit className="menu-icon" /> Больничные {/* Новый пункт меню */}
                    </Link>
                </li>

            </ul>
            <ul className="menu-right">
                <li>
                    <a onClick={handleDownloadPivotReport} className="menu-button">
                        Сводная
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default ManagerMenu;
