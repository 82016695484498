// AnalyticsPage.js

import React, { useState, useEffect } from 'react';
import FilterPanel from './FilterPanel';
import ChartsPanel from './ChartsPanel';
import { getAllClusters, getAggregatedDailyReports, getChartData  } from '../../api';
import Toast from '../Toast';

const AnalyticsPage = () => {
    const [selectedFactory, setSelectedFactory] = useState(null);
    const [selectedIndicator, setSelectedIndicator] = useState('all');
    const [chartData, setChartData] = useState([]);
    const [factories, setFactories] = useState([]);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState('');

    useEffect(() => {
        // Fetch factories and clusters from backend
        getAllClusters(false)
            .then(response => {
                setFactories(response.data);
            })
            .catch(error => {
                console.error('Error fetching factories:', error);
                setToastMessage('Ошибка загрузки заводов');
                setToastType('error');
            });
    }, []);


    useEffect(() => {
        if (selectedFactory) {
            const today = new Date();  // Сегодняшняя дата
            const oneYearAgo = new Date();
            oneYearAgo.setFullYear(today.getFullYear() - 1);  // Дата год назад

            // Форматируем даты в формат "YYYY-MM-DD"
            const todayFormatted = today.toISOString().split('T')[0];
            const oneYearAgoFormatted = oneYearAgo.toISOString().split('T')[0];

            // Вызываем новый метод для получения данных для графика
            getChartData(selectedFactory.id, oneYearAgoFormatted, todayFormatted)
                .then(data => {
                    setChartData(data);  // Устанавливаем данные для графика
                })
                .catch(error => {
                    console.error('Error fetching chart data:', error);
                    setToastMessage('Ошибка загрузки данных для графика');
                    setToastType('error');
                });
        }
    }, [selectedFactory]);


    return (
        <div style={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
            {/* Компонент панели фильтров */}
            <FilterPanel
                factories={factories}
                onFactorySelect={setSelectedFactory}
                onIndicatorChange={(e) => setSelectedIndicator(e.target.value)}
            />

            {/* Компонент панели с графиками */}
            {selectedFactory && (
                <ChartsPanel
                    chartData={chartData}
                    selectedFactory={selectedFactory}
                    indicator={selectedIndicator}
                />
            )}

            {toastMessage && (
                <Toast
                    message={toastMessage}
                    type={toastType}
                    onClose={() => setToastMessage(null)}
                />
            )}
        </div>
    );
};

export default AnalyticsPage;
