import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { format, subDays, addDays } from 'date-fns';
import './DailyReportsPage.css'; // Подключаем стили
import Toast from '../Toast';
import { getAllFactories, getDailyReports, getPreviousDayReport, getDailyReportsWithAllFactories, saveFactoryPlan,
            getFactoryPlansForMonth, downloadDailyReport } from '../../api';  // Импортируем функции из api.js
import { colorOptionsFind, colorOptions } from '../references/ColorOptions';

const DailyReportsPage = () => {
    const [factories, setFactories] = useState([]);
    const [selectedFactories, setSelectedFactories] = useState([]);
    const [date, setDate] = useState(format(subDays(new Date(), 0), 'yyyy-MM-dd')); // инициализированная дата
    const [reports, setReports] = useState([]);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(''); // success или error
    const [isExporting, setIsExporting] = useState(false); // Отслеживаем процесс выгрузки
    const [previousDayTotals, setPreviousDayTotals] = useState(null); // Для хранения итогов за вчера
    const [hideNoReports, setHideNoReports] = useState(false); // Состояние чекбокса для скрытия строк без отчетов
    const [plans, setPlans] = useState({}); // Состояние для хранения планов по каждому заводу
    const [editingFactoryId, setEditingFactoryId] = useState(null); // Состояние для отслеживания редактируемой ячейки
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });



    // Получаем заводы при загрузке страницы
    useEffect(() => {
        getAllFactories()
            .then(response => setFactories(response.data))
            .catch(error => {
                setToastMessage(error.response?.data?.message || 'Ошибка при загрузке заводов: ' + error.message);
                setToastType('error');
            });
    }, []);

    // Получаем отчеты при изменении выбранных заводов или даты
    useEffect(() => {
            const factoryIds = selectedFactories.map(factory => factory.value);

            const month = format(new Date(date), 'yyyy-MM');  // Извлекаем месяц из выбранной даты

            getDailyReportsWithAllFactories(factoryIds, date)
                .then(response => setReports(response.data))
                .catch(error => {
                    setToastMessage(error.response?.data?.message || 'Ошибка при загрузке отчетов: ' + error.message);
                    setToastType('error');
                    console.error('Ошибка при загрузке отчетов:', error);
                });

            // Запрашиваем планы на выбранный месяц
            getFactoryPlansForMonth(factoryIds, month)
                .then(response => {
                                    const newPlans = {};
                                    response.data.forEach(plan => {
                                        newPlans[plan.factory.id] = plan.plan || '';  // Если план пуст, сохраняем как пустую строку
                                    });
                                    setPlans(newPlans);
                })
                .catch(error => {
                                    setToastMessage(error.response?.data?.message || 'Ошибка при загрузке планов: ' + error.message);
                                    setToastType('error');
                });
    }, [selectedFactories, date]);



    // Получаем итоги за предыдущие дни
    useEffect(() => {
        const fetchPreviousDayTotals = async () => {
            try {
                const factoryIds = selectedFactories.map(factory => factory.value);

                const response = await getPreviousDayReport(factoryIds, date);
                if (response.data) {
                    setPreviousDayTotals(response.data); // Сохраняем итоги за найденный день
                } else {
                    setPreviousDayTotals(null); // Если нет данных, сбрасываем
                }
            } catch (error) {
                console.error('Ошибка при загрузке отчетов за предыдущий день:', error);
            }
        };

        fetchPreviousDayTotals();
    }, [selectedFactories, date]);


    const sortedReports = React.useMemo(() => {
        let sortableReports = [...reports];
        if (sortConfig.key !== null) {
            sortableReports.sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];
                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableReports;
    }, [reports, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };




    // Сохраняем план на бэкенд
    const handlePlanChange = async (factoryId, plan) => {
        if (plan == undefined) return;
        const month = format(new Date(date), 'yyyy-MM'); // Извлекаем месяц из выбранной даты
        try {
            await saveFactoryPlan(factoryId, month, plan);
            setToastMessage('План успешно сохранен');
            setToastType('success');
        } catch (error) {
            setToastMessage(error.response?.data?.message || 'Ошибка при сохранении плана: ' + error.message);
            setToastType('error');
        }
    };

    // Вспомогательная функция для расчета итогов
    const calculateTotalsForReports = (reportsData) => {
        const totals = { atStationToday: 0, totalInCity: 0, newWorkersToday: 0, onMedicalToday: 0, leftFactoryToday: 0, onVacationToday: 0, requestToday: 0 };
        reportsData.forEach(report => {
            totals.atStationToday += report.atStationToday;
            totals.totalInCity += report.totalInCity;
            totals.newWorkersToday += report.newWorkersToday;
            totals.onMedicalToday += report.onMedicalToday;
            totals.leftFactoryToday += report.leftFactoryToday;
            totals.onVacationToday += report.onVacationToday;
            totals.requestToday += report.requestToday;
        });
        return totals;
    };


    const factoryOptions = factories.map(factory => ({
        value: factory.id,
        label: factory.name
    }));

    const decrementDate = () => setDate(format(subDays(new Date(date), 1), 'yyyy-MM-dd'));
    const incrementDate = () => setDate(format(addDays(new Date(date), 1), 'yyyy-MM-dd'));



    // Обработчик изменения чекбокса
    const handleHideNoReportsChange = (e) => {
        setHideNoReports(e.target.checked);
    };




    const calculateTotals = () => {
        const totals = { atStationToday: 0, totalInCity: 0, newWorkersToday: 0, onMedicalToday: 0, leftFactoryToday: 0, onVacationToday: 0, requestToday: 0 };
        reports.forEach(report => {
            totals.atStationToday += report.atStationToday;
            totals.totalInCity += report.totalInCity;
            totals.newWorkersToday += report.newWorkersToday;
            totals.onMedicalToday += report.onMedicalToday;
            totals.leftFactoryToday += report.leftFactoryToday;
            totals.onVacationToday += report.onVacationToday;
            totals.requestToday += report.requestToday;
        });
        return totals;
    };

    const totals = calculateTotals();
    const exportToExcel = async () => {
        setIsExporting(true); // Устанавливаем состояние загрузки
        try {
            const factoryIds = selectedFactories.map(factory => factory.value).join(',');  // Преобразуем массив в строку через запятую

            // Отправляем запрос на бэкенд для генерации отчета
            const response = await downloadDailyReport(factoryIds, date);

            // Создаем ссылку для скачивания файла
            const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Дневной_отчет_${date}.xlsx`;
            link.click();
            setToastMessage('Отчет успешно выгружен');
            setToastType('success');
        } catch (error) {
            setToastMessage('Ошибка при выгрузке отчета: ' + error.message);
            setToastType('error');
            console.error('Ошибка при выгрузке отчета:', error);
        } finally {
            setIsExporting(false); // Возвращаем кнопку в доступное состояние
        }
    };

    const formatTextWithLineBreaks = (text) => {
        return text?.trim().split(/\r\n|\n|\r/)?.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    // Обработчик изменения значения плана
    const handlePlanInputChange = (factoryId, value) => {
        const updatedPlans = { ...plans, [factoryId]: value };
        setPlans(updatedPlans);
    };

    // Сохранение плана при потере фокуса или нажатии клавиши Enter
    const handleBlurOrEnter = (factoryId, value) => {
        handlePlanChange(factoryId, value); // Сохраняем изменения на бэкенде
        setEditingFactoryId(null); // Убираем режим редактирования
    };

    const handleKeyDown = (e, factoryId, value) => {
        if (e.key === 'Enter') {
            handleBlurOrEnter(factoryId, value); // Сохраняем при нажатии Enter
        }
        if (e.key === 'Escape') {
            setEditingFactoryId(null);
        }
    };

    return (
        <div className="daily-reports__page-container">
        <div className="content-container">
            <div className="daily-reports__filter-container">
                <div className="filter-group">
                    <Select
                        id="factories"
                        options={factoryOptions}
                        isMulti
                        onChange={setSelectedFactories}
                        placeholder="Выберите заводы"
                        className="factory-select"
                    />
                </div>
                <div className="date-controls">
                    <button className="date-button" onClick={decrementDate}>{"◀"}</button>
                    <input
                        id="date"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="date-input"
                    />
                    <button className="date-button" onClick={incrementDate}>{"▶"}</button>
                </div>
                <div className="export-controls">
                    <button
                        className="export-button"
                        onClick={exportToExcel}
                        disabled={isExporting} // Дизейблим кнопку, если идет выгрузка
                    >
                        {isExporting ? 'Загрузка...' : 'Выгрузить в Excel'} {/* Меняем текст на кнопке */}
                    </button>

                </div>

                <div className="hide-no-reports-checkbox">
                        <label>
                            <input
                                type="checkbox"
                                checked={hideNoReports}
                                onChange={handleHideNoReportsChange}
                            />
                            Скрыть<br/>пустые
                        </label>
                    </div>
            </div>

            <div className="daily-reports__report-table">
            <div className="table-container">
                <table className="reports-table">
                <thead>
                    <tr>
                        <th className="td_idx"></th>
                        <th onClick={() => requestSort('factory.cluster.name')}>
                            Кластер
                        </th>
                        <th onClick={() => requestSort('factory.name')}>
                            Наименование предприятия
                        </th>
                        <th style={{ backgroundColor: 'lightyellow', color: 'black' }}>
                            ПЛАН
                        </th>
                        <th className="td_num" onClick={() => requestSort('atStationToday')}>
                            Сколько<br />сегодня у<br />станка? {getSortIndicator('atStationToday')}
                        </th>
                        <th className="td_num" onClick={() => requestSort('totalInCity')}>
                            Сколько<br />всего в<br />городе? {getSortIndicator('totalInCity')}
                        </th>
                        <th className="td_num" onClick={() => requestSort('newWorkersToday')}>
                            Новые<br />сотруд-<br />ники {getSortIndicator('newWorkersToday')}
                        </th>
                        <th className="td_num" onClick={() => requestSort('onMedicalToday')}>
                            На офрмл<br />м/о {getSortIndicator('onMedicalToday')}
                        </th>
                        <th className="td_num" onClick={() => requestSort('leftFactoryToday')}>
                            Уволено<br />сегодня {getSortIndicator('leftFactoryToday')}
                        </th>
                        <th className="td_num" onClick={() => requestSort('onVacationToday')}>
                            На м/вх<br />отпуске {getSortIndicator('onVacationToday')}
                        </th>
                        <th className="td_num" onClick={() => requestSort('requestToday')}>
                            Заявка<br />сегодня<br />(кол-во) {getSortIndicator('requestToday')}
                        </th>
                        <th className="td_num">Заявка<br />специальности</th>
                        <th className="td_num">На отработке</th>
                        <th className="td_table">ТИП</th>
                    </tr>
                </thead>


                    <tbody>
                            {sortedReports.map((report, idx) => (

                            <tr key={report.id ? report.id : `report-${idx}`} hidden={hideNoReports && report.reportNotProvided}
                                className={`${report.reportNotProvided ? 'report-not-provided' : ''} `}
                            >
                                <td className="td_idx" style={{backgroundColor: `${colorOptionsFind(report.factory.cluster?.color)}`}}>{idx+1}</td>
                                <td className="td_idx">
                                    {report.factory.cluster?.name}

                                                                    {report.updatedAt && (
                                                                        <small className="td_updated_at">
                                                                          {new Date(report.updatedAt).toLocaleString('ru-RU', {
                                                                            day: '2-digit',
                                                                            month: '2-digit',
                                                                            year: 'numeric',
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                          })}
                                                                        </small>
                                                                      )}
                                </td>
                                <td className="td_factory">
                                    <div className="td_factory_name" style={{ textDecoration: report.factory.deleted ? 'line-through' : 'none' }}>{report.factory.name}</div>
                                    {report.master && (
                                        <>
                                            <div className="td_master_fio">Мастер - {report.master?.fio}</div>
                                            <div className="td_master_phone">{report.master?.phone}</div>
                                        </>
                                    )}
                                </td>

                                {/* Редактируемая ячейка для плана */}
                                <td
                                    className="td_plan"
                                    style={{ backgroundColor: 'lightyellow', maxWidth: '30px', textAlign: 'center' }}
                                    onClick={() => setEditingFactoryId(report.factory.id)}  // Включаем редактирование
                                >
                                    {editingFactoryId === report.factory.id ? (
                                        <input
                                            type="number"
                                            min="0"
                                            value={plans[report.factory.id] || ''}
                                            onChange={(e) => {
                                                e.target.value = Number(e.target.value);
                                                handlePlanInputChange(report.factory.id, e.target.value)
                                                }
                                            }
                                            onBlur={() => handleBlurOrEnter(report.factory.id, plans[report.factory.id])} // Сохранение при потере фокуса
                                            onKeyDown={(e) => handleKeyDown(e, report.factory.id, plans[report.factory.id])} // Сохранение при нажатии Enter
                                            autoFocus
                                        />
                                        ) : (
                                            plans[report.factory.id] || '—' // Отображаем значение или символ "—", если пусто
                                        )}
                                </td>

                                {report.reportNotProvided ? (
                                    <td colSpan="10" className="td_report_not_provided">Отчет не предоставлен</td>
                                ) : (
                                <>
                                    <td className="td_num">{report.atStationToday}</td>
                                    <td className="td_num">{report.totalInCity}</td>
                                    <td className="td_num">{report.newWorkersToday}</td>
                                    <td className="td_num">{report.onMedicalToday}</td>
                                    <td className="td_num">{report.leftFactoryToday}</td>
                                    <td className="td_num">{report.onVacationToday}</td>
                                    <td className="td_num">{report.requestToday}</td>
                                    <td className="td_text">{formatTextWithLineBreaks(report.requestTodayComment)}</td>
                                    <td className="td_text">{formatTextWithLineBreaks(report.whoOtrabotkaToday)}</td>

                                    <td className="td_table">
                                        <table className="td_table">
                                        <tbody>
                                        <tr>
                                            <td>{formatTextWithLineBreaks(report.whoOnApproval)}</td>
                                            <th>согласование</th>
                                        </tr>

                                        <tr>
                                            <td>{formatTextWithLineBreaks(report.whoInCity)}</td>
                                            <th>в городе</th>
                                        </tr>

                                        <tr>
                                            <td>{report.whoAtFactory}</td>
                                            <th>выход</th>
                                        </tr>

                                        <tr>
                                            <td>{report.whoOnSickLeave}</td>
                                            <th>больн/отгул</th>
                                        </tr>

                                        <tr>
                                            <td>{report.whoReturnedFromVacation}</td>
                                            <th>возврат м/в</th>
                                        </tr>

                                        <tr>
                                            <td>{report.whoLeftForVacation}</td>
                                            <th>убыл в м/а</th>
                                        </tr>

                                        <tr>
                                            <td>{report.whoFiredToday}</td>
                                            <th>уволен</th>
                                        </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        {reports.length > 1 && (
                                <>
                                    <tr className="daily-reports__totals-row">
                                            <td/>
                                            <td><strong>Итог</strong></td>
                                            <td/>
                                            <td/>
                                            <td className="td_num" title='Сколько сегодня у станка?'>{totals.atStationToday}</td>
                                            <td className="td_num" title='Сколько всего в городе?'>{totals.totalInCity}</td>
                                            <td className="td_num" title='Новые сотрудники'>{totals.newWorkersToday}</td>
                                            <td className="td_num" title='На офрмл м/о'>{totals.onMedicalToday}</td>
                                            <td className="td_num" title='Уволено сегодня'>{totals.leftFactoryToday}</td>
                                            <td className="td_num" title='На м/вх отпуске'>{totals.onVacationToday}</td>
                                            <td className="td_num" title='Заявка сегодня (кол-во)'>{totals.requestToday}</td>
                                    </tr>
                                    {previousDayTotals && (
                                        <tr className="daily-reports__totals-row">
                                            <td/>
                                            <td><strong>Итог вчера</strong></td>
                                            <td/>
                                            <td/>
                                            <td className="td_num" title='Сколько сегодня у станка?'>{previousDayTotals.atStationToday}</td>
                                            <td className="td_num" title='Сколько всего в городе?'>{previousDayTotals.totalInCity}</td>
                                            <td className="td_num" title='Новые сотрудники'>{previousDayTotals.newWorkersToday}</td>
                                            <td className="td_num" title='На офрмл м/о'>{previousDayTotals.onMedicalToday}</td>
                                            <td className="td_num" title='Уволено сегодня'>{previousDayTotals.leftFactoryToday}</td>
                                            <td className="td_num" title='На м/вх отпуске'>{previousDayTotals.onVacationToday}</td>
                                            <td className="td_num" title='Заявка сегодня (кол-во)'>{previousDayTotals.requestToday}</td>
                                        </tr>
                                    )}
                                </>
                            )}
                    </tfoot>

                </table>
            </div>
            </div>
        </div>

        {toastMessage && (
                <Toast
                    message={toastMessage}
                    type={toastType}
                    onClose={() => setToastMessage(null)}
                />
        )}
        </div>
    );
};

export default DailyReportsPage;
