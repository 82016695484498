import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; // Используем переменную окружения
console.log('API URL:', API_URL);


// Создаем экземпляр Axios
const apiClient = axios.create({
    baseURL: API_URL,
});

// Интерсептор для установки токена перед каждым запросом
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Интерцептор для обработки ошибок
apiClient.interceptors.response.use(
    (response) => {
        return response;  // Возвращаем ответ, если запрос прошел успешно
    },
    (error) => {
        if (error.response && error.response.status === 403) {
            const token = localStorage.getItem('token');
            if (token) {
                localStorage.removeItem('token');  // Удаляем токен из localStorage
            }
            window.location.href = '/';  // Перенаправляем на главную страницу
        } else {
             // Можно добавить обработку других ошибок, если необходимо
             console.error('Ошибка сервера:', error);

             if (error?.response?.data?.message){
                alert(error?.response?.data?.message);
             } else {
                alert('Ошибка на стороне сервера. Попробуйте еще раз или обратитесь к администратору\n[' + error.message + ']');
             }
        }
        return Promise.reject(error);
    }
);

// Функция для установки токена в localStorage
export const setAuthToken = (token) => {
    if (token) {
        localStorage.setItem('token', token);  // Сохраняем токен в localStorage
    } else {
        localStorage.removeItem('token');  // Удаляем токен, если передан null
    }
};

// Получить информацию о мастере по уникальной ссылке
export const getMasterByLink = async (uniqueLink) => {
        let response = await apiClient.get(`${API_URL}/masters/id/${uniqueLink}`);
        setAuthToken(response.data.token);
        return response.data.master;
};

// Создать отчет для мастера
export const createReport = (report) => {
    return apiClient.post(`${API_URL}/reports/create`, report);
};
// Получить отчет по мастеру и заводу за текущую дату
export const getReportToday = (factoryId) => {
    return apiClient.get(`${API_URL}/reports/today?factoryId=${factoryId}`);
};


// Создать нового мастера
export const createMaster = (master) => {
    return apiClient.post(`${API_URL}/masters/create`, master);
};

// Заблокировать мастера
export const blockMaster = (masterId) => {
    return apiClient.put(`${API_URL}/masters/block/${masterId}`);
};

// Разблокировать мастера
export const unblockMaster = (masterId) => {
    return apiClient.put(`${API_URL}/masters/unblock/${masterId}`);
};

// Удалить мастера
export const deleteMaster = (masterId) => {
    return apiClient.delete(`${API_URL}/masters/delete/${masterId}`);
};

// Обновить мастера
export const updateMaster = (id, master) => {
    return apiClient.put(`${API_URL}/masters/update/${id}`, master);
};

// Проверка доступа менеджера по коду
export const checkManagerAccess = async (code) => {
    let response = await apiClient.post(`${API_URL}/manager/login`, {"code": code}, {
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded'  // Устанавливаем заголовок для формы
         }
    });
    setAuthToken(response.data.token);
    return response;
}


// Получить список всех заводов, привязанных к мастеру
export const getFactoriesForMaster = (masterId) => {
    return apiClient.get(`${API_URL}/factories/master`);
};


// Обновить завод
export const createOrUpdateFactory = (factory) => {
    let response = apiClient.post(`${API_URL}/factories/createOrUpdate`, factory);
    return response;
};

// Удалить завод
export const deleteFactory = (id) => {
    return apiClient.delete(`${API_URL}/factories/delete/${id}`);
};

// Получить доступные заводы для мастера
export const getAvailableFactoriesForMaster = (masterId) => {
    return apiClient.get(`${API_URL}/masters/${masterId}/available-factories`);
};

// Получить список всех заводов
export const getAllFactories = () => {
    return apiClient.get(`${API_URL}/factories/all`);
};

// Получить список всех заводов
export const getAllActiveFactories = () => {
    return apiClient.get(`${API_URL}/factories/all_active`);
};

// Получить список всех мастеров
export const getAllMasters = () => {
    return apiClient.get(`${API_URL}/masters/all`);
};


// Загрузка табеля (timesheet) в базу
export const uploadTimesheet = (timesheetData) => {
    return apiClient.post(`${API_URL}/timesheets/upload`, timesheetData);
};

// Получение табеля для завода и месяца
export const getTimesheetForFactoryAndMonth = (factoryId, month) => {
    return apiClient.get(`${API_URL}/timesheets`, { params: { factoryId, month } });
};

// Получить последний сохраненный отчет
export const getLastSavedReport = (factoryId) => {
    return apiClient.get(`${API_URL}/reports/last?factoryId=${factoryId}`);
};

// Получить отчеты за предыдущие дни (в пределах 10 дней назад)
export const getPreviousDayReport = (factoryIds, date) => {
    return apiClient.get(`${API_URL}/reports/findPreviousDayReport`, {
        params: {
            factoryIds: factoryIds.join(','),  // Преобразуем массив в строку
            date
        }
    });
};


// Получить отчеты по выбранным заводам и дате
export const getDailyReports = (factoryIds, date) => {
    return apiClient.get(`${API_URL}/reports/findDailyReport`, {
        params: {
            factoryIds: factoryIds.join(','),  // Преобразуем массив в строку
            date
        }
    });
};


export const getAggregatedDailyReports = async (factoryId, month) => {
    try {
        const response = await apiClient.get('/reports/aggregated-daily-reports', {
            params: {
                factoryId,
                month
            }
        });
        return response.data;
    } catch (error) {
        console.error("Ошибка при получении агрегированных данных:", error);
        alert("Ошибка при получении агрегированных данных");
        return [];
    }
};

// Получить отчеты по всем заводам, включая те, для которых отчеты не предоставлены
export const getDailyReportsWithAllFactories = (factoryIds, date) => {
    return apiClient.get(`${API_URL}/reports/findDailyReportWithAllFactories`, {
        params: {
            factoryIds: factoryIds.join(','),  // Преобразуем массив в строку
            date
        }
    });
};

export const downloadDailyReport = (factoryIds, date) => {
    return apiClient.get(`${API_URL}/reports/downloadDailyReport`, {
        params: {
            factoryIds: factoryIds.length ? factoryIds : null, // передаем массив или null
            date
        },
        responseType: 'blob'
    });
};

export const downloadPivotReport = async (factoryIds) => {
    try {
        const response = await apiClient.get(`${API_URL}/reports/downloadPivotReport`, {
            responseType: 'blob'
        });

        // Создаем blob для файла
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Сводная_отчет.xlsm`; // Имя файла
        document.body.appendChild(link);
        link.click();
        link.remove(); // Удаляем ссылку после скачивания
    } catch (error) {
        console.error('Ошибка при скачивании сводного отчета', error);
    }
};

// сохранение плана
export const saveFactoryPlan = (factoryId, month, plan) => {
    return apiClient.post(`${process.env.REACT_APP_API_URL}/factory-plans/save`, null, {
        params: { factoryId, month, plan }
    });
};

// Получить планы для заводов на выбранный месяц
export const getFactoryPlansForMonth = (factoryIds, month) => {
    return apiClient.get(`${process.env.REACT_APP_API_URL}/factory-plans/getPlansForMonth`, {
        params: {
            month
        }
    });
};

export const getAllClusters = (withDeleted) => {
    return apiClient.get(`${API_URL}/clusters/all`, {
        params: {withDeleted}
    });
};

export const updateFactoryOrderInCluster = (clusterId, factoryIds) => {
    return apiClient.put(`${API_URL}/clusters/updateOrder/${clusterId}`, factoryIds);
};

// Создать новый кластер
export const createCluster = (clusterData) => {
    return apiClient.post(`${API_URL}/clusters/create`, clusterData);
};

// Обновить информацию о кластере
export const updateCluster = (clusterId, clusterData) => {
    return apiClient.put(`${API_URL}/clusters/update/${clusterId}`, clusterData);
};

// Удалить кластер (только если он пустой)
export const deleteCluster = (clusterId) => {
    return apiClient.delete(`${API_URL}/clusters/delete/${clusterId}`);
};


// Обновить порядок кластеров
export const updateClusterOrder = (clusterIds) => {
    return apiClient.put(`${API_URL}/clusters/updateOrder`, clusterIds);
};

export const getChartData = async (factoryId, startDate, endDate) => {
    const response = await apiClient.get(`/reports/chartData`, {
        params: {
            factoryId,
            startDate,
            endDate,
        },
    });
    return response.data;
};

// Получить отчеты за весь месяц для выбранного завода
export const getMonthlyReports = (factoryId, month) => {
    return apiClient.get(`/reports/monthlyReports`, {
        params: { factoryId, month }
    });
};

// Функция для выгрузки отчета в Excel
export const downloadSickLeaveReport = (factoryName, factoryId, month) => {
    return apiClient.get(`/reports/sickLeaveDownload`, {
        params: { factoryId, month },
        responseType: 'blob',  // Ожидаем бинарный файл
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${factoryName}_${month}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};


// Функция для подготовки и скачивания файла
export const downloadDiscrepancyMatrix = async (selectedMonth, factoryData) => {
    try {
        // Первый запрос для подготовки файла
        const response = await apiClient.post('/reports/prepareDiscrepancyMatrix', {
            selectedMonth: selectedMonth,
            factoryData: factoryData,
        });

        // Получаем URL для скачивания файла из ответа
        const downloadUrl = `${API_URL}/reports/downloadDiscrepancyMatrix?token=${response.data.downloadToken}`;

        // Инициируем скачивание файла
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `matrix_${selectedMonth}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Ошибка при подготовке файла для скачивания', error);
        alert('Ошибка при подготовке файла для скачивания. Пожалуйста, попробуйте еще раз.');
    }
};


// Upload a part of the timesheet (days 1-15 or 16-31)
export const uploadTimesheetPart = (timesheetData) => {
    return apiClient.post(`/timesheets/uploadPart`, timesheetData);
};

// Clear the timesheet for a factory and month
export const clearTimesheet = (factoryId, month) => {
    return apiClient.post(`/timesheets/clear`, { factoryId, month });
};




